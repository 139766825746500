import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

// For API Requests
import axios from "axios";

function ReceiptTable() {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [dashoo, setDasho] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);

  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [DealerDetails, setDealerDetails] = useState({
    product_id: 0,
    code: "",
    description: "",
    barcode: "",
    name: "",
    price: 0,
    cost: 0,
    category: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/inventory/receipts/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username]);

  const columns = [
    {
      field: "receipt_id",
      headerName: "Receipt Number",
      flex: 2,
      minWidth: 150,
    },

    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 200 },
    {
      field: "paid_amount_usd",
      headerName: "Paid USD",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "paid_amount_lbp",
      headerName: "Paid LBP",
      flex: 2,
      minWidth: 100,
    },
    { field: "exchange_rate", headerName: "Rate", flex: 2, minWidth: 100 },
    {
      field: "balance_record",
      headerName: "Balance Record",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "discount_usd",
      headerName: "Discount USD",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "discount_lbp",
      headerName: "Discount LBP",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "returned_lbp",
      headerName: "Returned LBP",
      flex: 2,
      minWidth: 100,
    },
    { field: "initiator", headerName: "Cashier", flex: 2, minWidth: 100 },
    {
      field: "date",
      headerName: "Date",
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        date.setHours(date.getHours());
        return date.toLocaleString();
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Button
          variant="danger"
          onClick={() => handleOpenModal(params.row.receipt_id)}
        >
          Cancel
        </Button>
      ),
    },
  ];

  const getRowId = (row) => row.receipt_id;
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenModal = (receiptId) => {
    setSelectedReceiptId(receiptId);
    setShowo(true);
  };

  const handleCloseModal = () => {
    setShowo(false);
    setSelectedReceiptId(null);
  };

  const handleConfirm = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/receipt/cancel/`,
        { receipt_id: selectedReceiptId, username: user.username },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        // Optionally, refresh the data or update the state to reflect the changes
        console.log("Receipt confirmed:", response.data);
      }
    } catch (error) {
      console.error("Error confirming receipt:", error);
    } finally {
      handleCloseModal();
      fetchData();
    }
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const sumField = (field) => {
    return filteredRows.reduce((total, row) => {
      const value = parseFloat(row[field]);
      return isNaN(value) ? total : total + value;
    }, 0);
  };

  const sumPaidUsd = sumField("paid_amount_usd");
  const sumPaidLbp = sumField("paid_amount_lbp");
  const sumReturnedUsd = sumField("returned_usd");
  const sumReturnedLbp = sumField("returned_lbp");

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
          color: "white",
        }}
      >
        <div>Paid USD: {sumPaidUsd.toFixed(2)}</div>
        <div>Paid LBP: {sumPaidLbp.toFixed(2)}</div>
        <div>Returned USD: {sumReturnedUsd.toFixed(2)}</div>
        <div>Returned LBP: {sumReturnedLbp.toFixed(2)}</div>
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooter,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this receipt?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReceiptTable;
