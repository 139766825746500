import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function Formsa(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let intsub = props.intsu;
  console.log(intsub);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  let intplan = props.intpa;
  let sub = props.su;
  let u = props.usr;
  let f = props.full;
  let s = props.sit;
  let c = props.con;
  let o = props.opti;
  let p = props.plana;
  let exp = props.exp;
  let du = props.due;
  let paymentDate = props.paymentDate;
  console.log(exp);

  const [username, setusername] = useState("");
  const [pass] = useState(props.pass);

  const [password, setpassword] = useState("");
  const [site, setsite] = useState("");
  const [due, setdue] = useState("");
  const [suffix, setsuffix] = useState("");

  const [subnotes, setnotes] = useState("");
  const [label, setlabel] = useState([]); // Initialize as an array for multiple selections
  let [labels, setlabels] = useState([]);

  const [consumption_option, setcon] = useState("");
  const [connectivity, setcona] = useState("");
  const [expiration_date, setda] = useState("");
  //const [payment_date, setpa] = useState("");

  const [expiryDateDisabled, setExpiryDateDisabled] = useState(true);
  let [plans, setplans] = useState([]);
  const [client, setclient] = useState("");
  const [plani, setplano] = useState(intplan);
  let [notes, setcil] = useState([]);

  const [clients, setClients] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [consumptionDisabled, setConsumptionDisabled] = useState(false);
  const [wirelessAccessPoint, setWirelessAccessPoint] = useState("");

  const [sites, setSites] = useState([]);
  const [wirelessAccessPoints, setWirelessAccessPoints] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");

  console.log(expiration_date);
  console.log(plani);
  console.log(client);
  console.log(expiryDateDisabled);
  useEffect(() => {
    let isMounted = true;

    const getclients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setcil(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getplans = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/plans/internet`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setplans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getlabels = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/labels/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setlabels(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getpermisions = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setExpiryDateDisabled(response.data === "yes");
        }
      } catch (error) {
        console.error(error);
      }
    };
    getpermisions();
    getclients();
    getplans();
    getlabels();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/sites/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: { username: user.username },
        });

        if (response.status === 200) {
          const options = response.data.map((item) => ({
            label: item.site_name,
            value: item.site_id,
          }));
          setSites(options);
        }
      } catch (error) {
        console.error("Error fetching sites:", error);
      }
    };

    fetchSites();
  }, [authTokens.access, user.username]);

  const fetchWirelessAccessPoints = async (siteName) => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/wirelessAccessPoint/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: { username: user.username, site: siteName },
        }
      );

      if (response.status === 200) {
        const options = response.data.map((item) => ({
          label: item.wireless_access_point_name,
          value: item.wireless_access_point_id,
        }));
        setWirelessAccessPoints(options);
      }
    } catch (error) {
      console.error("Error fetching wireless access points:", error);
    }
  };

  const options = notes.map((item) => {
    return {
      label: item.fullname_en,
      value: item.client_id,
    };
  });

  const plano = plans.map((item) => {
    return {
      label: item.plan_name,
      value: item.internet_plan_id,
    };
  });
  const labelss = labels.map((item) => {
    return {
      label: item.label_name,
      value: item.label_id,
    };
  });
  const opta = [
    { value: "LIMITED", label: "LIMITED" },
    { value: "UNLIMITED", label: "UNLIMITED" },
  ];
  const cona = [
    { value: "Fiber", label: "Fiber" },
    { value: "Wireless", label: "Wireless" },
  ];
  const dua = [
    { value: "First", label: "First" },
    { value: "Half", label: "Half" },
  ];

  // Function to show the message for a given duration
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/`,
      {
        Action: "Put",
        Subscription_id: sub,
        internet_subscription_id: intsub,
        internet_plan_id: intplan,
        username: username,
        client_id: client,
        plan_id: plani,
        consumption_option: consumption_option,
        connectivity: connectivity,
        expiry_date: expiration_date,
        //payment_date: payment_date,
        site: site,
        due: due,
        suffix: suffix,
        label: label,
        notes: subnotes,
        password: password,
        wireless_access_point_id: wirelessAccessPoint,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        // refreshPage(); // You may or may not want to reload the page after successful submission
        showMessage("Edit successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Edit failed!", "danger"); // Show the error message
      });
  };
  const customFilter = (option, inputValue) => {
    const words = inputValue.toLowerCase().split(" ");
    return words.every((word) => option.label.toLowerCase().includes(word));
  };

  const handleClientChange = (selectedOption) => {
    setclient(selectedOption);
    const filteredUsernames = clients
      .filter((item) => item.client_id === selectedOption.value)
      .map((item) => ({
        label: item.username,
        value: item.subscription_id,
      }));
    setUsernames(filteredUsernames);
    setusername(null); // Reset the username selection when client changes
  };

  const handlePlanChange = (opt) => {
    setplano(opt.value);
    const limitedPlans = ["PREMIUM", "GOLD", "PLATINUM", "DIAMOND"];
    if (limitedPlans.includes(opt.label)) {
      setplano(opt.value);
      setcon("UNLIMITED");
      setConsumptionDisabled(true);
    } else {
      setcon("LIMITED");
      setConsumptionDisabled(false);
    }
  };

  const copyPassword = () => {
    const textToCopy = password || pass;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        showMessage("Password copied!", "success");
      });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        showMessage("Password copied!", "success");
      } catch (err) {
        showMessage("Failed to copy password", "danger");
      }
      document.body.removeChild(textArea);
    }
  };

  const handleConnectivityChange = (opt) => {
    setcona(opt.value);
    if (opt.value !== "Wireless") {
      setSelectedSite("");
      setWirelessAccessPoints([]);
    }
  };

  const handleSiteChange = (opt) => {
    setSelectedSite(opt.value);
    const selectedSite = sites.find(
      (selectedSite) => selectedSite.value === opt.value
    )?.label;
    if (selectedSite) {
      setWirelessAccessPoints([]);
      fetchWirelessAccessPoints(selectedSite);
    }
  };

  return (
    <div className="testi">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
      ></link>
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Username:
              <input
                className="form-control"
                type="text"
                value={username}
                placeholder={u}
                onChange={(e) => setusername(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Password:
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  value={password}
                  placeholder={pass}
                  onChange={(e) => setpassword(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={copyPassword}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      marginLeft: "5px",
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </button>
                </div>
              </div>
            </label>
          </div>
          <div className="form-group">
            <label>
              Client Name:
              <Select
                className="search-line"
                placeholder={f}
                options={options}
                onChange={handleClientChange}
                filterOption={customFilter}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Plan Name:
              <Select
                className="search-line"
                placeholder={p}
                options={plano}
                onChange={handlePlanChange}
              />
            </label>
          </div>
          <label>
            Consumption Option:
            <Select
              className="search-line"
              options={opta}
              value={{ label: consumption_option, value: consumption_option }}
              isDisabled={consumptionDisabled}
              onChange={(opt) => setcon(opt.value)}
            />
          </label>

          <label>
            Site/Core:
            <input
              className="form-control"
              type="text"
              value={site}
              placeholder={s}
              onChange={(e) => setsite(e.target.value)}
            />
          </label>
          <label>
            Suffix:
            <input
              className="form-control"
              type="text"
              value={suffix}
              onChange={(e) => setsuffix(e.target.value)}
            />
          </label>
          <label>
            Due Date:
            <Select
              placeholder={du}
              className="search-line"
              options={dua}
              onChange={(opt) => setdue(opt.value)}
            />
          </label>
          <label>
            Labels:
            <Select
              className="search-line"
              options={labelss}
              isMulti // Enable multi-select
              onChange={
                (opts) => setlabel(opts.map((opt) => opt.value)) // Store selected label IDs
              }
            />
          </label>
          <label>
            Expiry Date:
            <input
              type="date"
              id="expiryDate"
              name="expiryDate"
              className="form-control"
              defaultValue={exp ? exp.substr(0, 10) : ""}
              onChange={(e) => setda(e.target.value)}
              disabled={!expiryDateDisabled}
            />
          </label>
          {/*<label>
            Payment Date:
            <input
              type="date"
              id="paymentDate"
              name="paymentDate"
              className="form-control"
              defaultValue={paymentDate ? paymentDate.substr(0, 10) : ""}
              onChange={(e) => setpa(e.target.value)}
              disabled={!expiryDateDisabled}
            />
          </label>*/}
          <label>
            Conectivity Option:
            <Select
              className="search-line"
              placeholder={c}
              options={cona}
              onChange={handleConnectivityChange}
            />
          </label>

          {connectivity === "Wireless" && (
            <label>
              Site:
              <Select
                className="search-line"
                placeholder="Select Site"
                options={sites}
                onChange={handleSiteChange}
              />
            </label>
          )}

          {connectivity === "Wireless" && selectedSite && (
            <label>
              Wireless Access Point:
              <Select
                className="search-line"
                placeholder="Select Wireless Access Point"
                options={wirelessAccessPoints}
                onChange={(opt) => setWirelessAccessPoint(opt.value)}
              />
            </label>
          )}
          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Edit
            </button>
          </div>
        </form>
      </div>

      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Formsa;
