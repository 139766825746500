import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function EditFiberCore(props) {
  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [status, setstatus] = useState("");
  const [mgrs, setmgrs] = useState("");
  const [type, settype] = useState("");
  const [sticker, setsticker] = useState("");
  const [map, setmap] = useState("");
  const [fiberMeter, setFiberMeter] = useState("");
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      props.closeModal();
      props.fetch();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };
  const types = [
    { value: "P2C", label: "P2C" },
    { value: "C2C", label: "C2C" },
    { value: "B2C", label: "B2C" },
    { value: "C2B", label: "C2B" },
  ];
  const state = [
    { value: "Ready", label: "Ready" },
    { value: "Pending", label: "Pending" },
    { value: "Active", label: "Active" },
  ];
  const stickers = [
    { value: "Private", label: "Private" },
    { value: "NO", label: "NO" },
    { value: "Yes", label: "Yes" },
  ];
  const options = [
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/fiber/cores/`,
      {
        Action: "Put",
        core_id: props.core_id,
        sticker: sticker,
        status: status,
        mgrs: mgrs,
        type: type,
        map: map,
        fiber_meters: fiberMeter,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Edit successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Edit failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi">
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Status:
              <Select
                className="search-line"
                placeholder={props.status}
                options={state}
                onChange={(opt) => setstatus(opt.value)}
              />
            </label>
            <label>
              Type:
              <Select
                className="search-line"
                placeholder={props.type}
                options={types}
                onChange={(opt) => settype(opt.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Sticker:
              <Select
                className="search-line"
                placeholder={props.sticker}
                options={stickers}
                onChange={(opt) => setsticker(opt.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              MAP:
              <Select
                className="search-line"
                placeholder={props.map}
                options={options}
                onChange={(opt) => setmap(opt.value)}
              />
            </label>
          </div>

          <label>
            Fiber Meters:
            <input
              className="form-control"
              type="number"
              value={fiberMeter}
              placeholder={props.meters}
              onChange={(e) => setFiberMeter(e.target.value)}
            />
          </label>
          <label>
            Location:
            <input
              className="form-control"
              type="text"
              value={mgrs}
              placeholder={props.mgrs}
              onChange={(e) => setmgrs(e.target.value)}
            />
          </label>

          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Edit
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default EditFiberCore;
