import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function AddService() {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [username, setusername] = useState(null); // Change to null
  const [client, setclient] = useState(null); // Change to null
  const [service, setservice] = useState("");
  const [services, setservices] = useState([]);
  const [clients, setclients] = useState([]);
  const [usernames, setusernames] = useState([]);

  const validate = () => {
    return username !== null; // Check for null
  };

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 3 seconds
    setTimeout(() => {
      refreshPage();
    }, 1200);

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  useEffect(() => {
    let isMounted = true;

    const getclients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setclients(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getservices = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/services/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setservices(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getusers = async () => {
      if (!client) return; // Fetch only if client is selected
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/discount/add/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              client_id: client,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setusernames(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getclients();
    getservices();
    getusers();

    return () => {
      isMounted = false;
    };
  }, [client]); // add client as a dependency

  const users = usernames.map((item) => {
    return {
      label: item.username,
      value: item.subscription_id,
    };
  });
  const clien = clients.map((item) => {
    return {
      label: item.fullname_en,
      value: item.client_id,
    };
  });
  const serve = services.map((item) => {
    return {
      label: item.name,
      value: item.service_id,
    };
  });

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/services/`,
      {
        Action: "Post",
        client_id: client,
        sub_id: username,
        service_id: service,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Creation successful!", "success"); // Show the success message
      })
      .catch((error) => {
        console.log(error);
        showMessage("Creation failed!", "danger"); // Show the error message
      });
  };

  return (
    <div className="testi" style={{ height: 550 }}>
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="name">
            <div className="form-group">
              <label>
                Client Name:
                <Select
                  className="search-line"
                  placeholder="client name"
                  options={clien}
                  onChange={(opt) => {
                    if (client !== opt.value) {
                      setusername(null); // Clear username only when client changes
                    }
                    setclient(opt.value);
                  }}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Username:
                <Select
                  className="search-line"
                  placeholder="username"
                  options={users}
                  onChange={(opt) => setusername(opt.value)}
                  isDisabled={!client} // Disable if no client is selected
                  value={users.find((user) => user.value === username) || null} // Set selected username value
                />
              </label>
            </div>
          </div>

          <label>
            Service Name:
            <Select
              className="search-line"
              placeholder="service name"
              options={serve}
              onChange={(opt) => setservice(opt.value)}
            />
          </label>
          <div className="fo">
            <button
              type="submit"
              className="btn btn-primary btn-md"
              disabled={!validate()}
            >
              Create
            </button>
          </div>
        </form>
      </div>
      {/* Popup Message */}
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default AddService;
