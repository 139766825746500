import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function EditService(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  const [message, setMessage] = useState(null);
  const [client, setClient] = useState("");
  const [username, setUsername] = useState("");
  const [service, setService] = useState("");
  const [clients, setClients] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [services, setServices] = useState([]);
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after a short time
    setTimeout(() => {
      refreshPage();
    }, 1200);

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchClients = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
        if (response.status === 200 && isMounted) {
          setClients(response.data);
          setUsername("");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchServices = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/services/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
        if (response.status === 200 && isMounted) {
          setServices(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUsers = async () => {
      if (!client) return; // Only fetch if a client is selected
      try {
        const response = await Axios.get(
          `${API_URL}/radius/subscriptions/discount/add/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              client_id: client,
            },
          }
        );
        if (response.status === 200 && isMounted) {
          setUsernames(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchClients();
    fetchServices();
    fetchUsers();

    return () => {
      isMounted = false;
    };
  }, [client]); // Fetch users when client changes

  const clientOptions = clients.map((item) => ({
    label: item.fullname_en,
    value: item.client_id,
  }));

  const userOptions = usernames.map((item) => ({
    label: item.username,
    value: item.subscription_id,
  }));

  const serviceOptions = services.map((item) => ({
    label: item.name,
    value: item.service_id,
  }));

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/radius/subscriptions/services/`,
      {
        Action: "Put",
        subscription_service_id: props.subscription_service_id, // Updated key here
        service: service,
        client_id: client,
        sub_id: username,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        showMessage("Update successful!", "success");
        console.log(props.subscription_service_id);
      })
      .catch((error) => {
        console.log(error);
        showMessage("Update failed!", "danger");
      });
  };

  return (
    <div className="testi" style={{ height: 550 }}>
      <div className="mod">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>
              Client:
              <Select
                className="search-line"
                options={clientOptions}
                onChange={(opt) => {
                  if (client !== opt.value) {
                    setUsername(null); // Clear username only when client changes
                  }
                  setClient(opt.value);
                }}
                placeholder={props.fullname_en}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Username:
              <Select
                className="search-line"
                options={userOptions}
                onChange={(opt) => setUsername(opt.value)}
                placeholder={props.username}
                value={
                  userOptions.find((user) => user.value === username) || null
                } // Set selected username value
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Service:
              <Select
                className="search-line"
                options={serviceOptions}
                onChange={(opt) => setService(opt.value)}
                placeholder={props.name}
              />
            </label>
          </div>
          <div className="fo">
            <button type="submit" className="btn btn-primary btn-md">
              Edit
            </button>
          </div>
        </form>
      </div>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default EditService;
