import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridFooter,
  GridPagination,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import ExcelSVG from './ExcelSVG.svg'
import * as XLSX from "xlsx";
import Checkbox from "@mui/material/Checkbox";
import CustomizedActions from "./BulkActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AuthContext from "../../context/AuthContext";
import axios from "axios";

import Notes from "./notes";
import ReceiptTable from "./ReceiptsTable";
import Notification from "./SendNotification";
import Cards from "./labels";
import Statement from "./statement";

function Special() {
  const { user } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [Client, setClient] = useState("");
  const [Fullname, setFullname] = useState("");

  const [Cl, setCl] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);

  // New state for postpone modal
  const [showCollectedModal,setShowCollectedModal]=useState(false);
  const [showPostponeModal, setShowPostponeModal] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  const CloseCollect = () => setShow(false);
  const ShowCollect = () => setShow(true);

  const CloseNotification = () => setShowNotification(false);
  const ShowNotification = () => setShowNotification(true);

  const CloseReceipt = () => setShowReceipt(false);
  const ShowReceipt = () => setShowReceipt(true);

  const ClosePostponeModal = () => setShowPostponeModal(false);
  const ShowPostponeModal = (sub_id) => {
    setSelectedSubscriptionId(sub_id);
    setShowPostponeModal(true);
  };

  const CloseCollectedModal = () => setShowCollectedModal(false);
  const ShowCollectedModal = (sub_id) => {
    setSelectedSubscriptionId(sub_id);
    setShowCollectedModal(true);
  };

  const [showStatement, setShowStatement] = useState(false);

  const CloseStatement = () => setShowStatement(false);
  const ShowStatement = () => setShowStatement(true);

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  const handlePostponeConfirm = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/accounting/info/Postpone/`,
        {
          action: "postpone",
          sub_id: selectedSubscriptionId,
          username: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        fetchData(); // Refresh data after successful postpone
        setShowPostponeModal(false);
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCollectedConfirm = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.post(
        `${API_URL}/accounting/info/Collected/`,
        {
          action: "toBeCollected",
          sub_id: selectedSubscriptionId,
          username: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      ); 
      if (response.status === 200) {
        fetchData(); // Refresh data after successful postpone
        setShowCollectedModal(false);
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/accounting/info/Special/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.username]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedRows = () => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return filteredRows.slice(start, end);
  };

  const areAllRowsSelected = () => {
    const currentViewRows = paginatedRows();
    const currentViewIds = currentViewRows.map(row => row.subscription_id);
    return currentViewIds.every(id => checkedRows.includes(id));
  };

  const handleSelectAllCheckboxes = () => {
    const currentViewRows = paginatedRows(); // Get rows currently visible
    const currentViewIds = currentViewRows.map(row => row.subscription_id);

    if (areAllRowsSelected()) {
      // Deselect if all currently visible rows are selected
      setCheckedRows(checkedRows.filter(id => !currentViewIds.includes(id)));
    } else {
      // Select all visible rows if not all are selected
      setCheckedRows([...checkedRows, ...currentViewIds.filter(id => !checkedRows.includes(id))]);
    }
  };
  const columns = [
    
    {
      field: "checkbox",
      headerName: "",
      width: 100,
      renderHeader: () => (
        <Checkbox
          indeterminate={checkedRows.length > 0 && checkedRows.length < data.length}
          checked={checkedRows.length === data.length}
          onChange={(event) => handleSelectAllCheckboxes(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          className="subcheck"
          checked={checkedRows.includes(params.row.subscription_id)}
          onChange={(event) =>
            handleCheckboxChange(event, params.row.subscription_id)
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: 100,
      cellClassName: (params) => getStatusCellStyle(params),
    },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "suffix",
      headerName: "Suffix",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "balance_usd",
      headerName: "Balance (USD)",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "balance",
      headerName: "Initial Balance",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "client_notes",
      headerName: "Client Notes",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            })  // Print this if the date is invalid
        }
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "due",
      headerName: "Due",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            })  // Print this if the date is invalid
        }
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "collector",
      headerName: "Collector",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "label",
      headerName: "MC Category",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "labels",
      headerName: "Labels",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Cards sub_id={params.row.subscription_id} />;
      },
    },
    {
      field: "note_details",
      headerName: "Latest Note",
      flex: 2,
      minWidth: 250,
      valueGetter: (params) => {
        const { note_user, latest_note } = params.row;
        return note_user && latest_note
          ? `${note_user}: ${latest_note}`
          : "No Note";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 500,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              setClient(params.row.subscription_id);
              setFullname(params.row.fullname_en);
              ShowCollect();
            }}
          >
            Notes
          </button>
          <button
            className="edit-client"
            onClick={() => {
              setCl(params.row.client_id);
              ShowReceipt();
            }}
          >
            Receipt
          </button>
          <button
            className="edit-client"
            onClick={() => {
              setClient(params.row.subscription_id);
              setFullname(params.row.fullname_en);
              ShowNotification();
            }}
          >
            Notifications
          </button>
          <button
            className="edit-client postpone-btn"
            onClick={() => ShowPostponeModal(params.row.subscription_id)}
          >
            Postpone
          </button>
          <button
            className="edit-client postpone-btn"
            onClick={() => ShowCollectedModal(params.row.subscription_id)}
          >
            To Be Collected
          </button>
          <button onClick={()=>{
            ShowStatement()
            setCl(params.row.client_id);
          }} className="edit-client" >
          Statement
          </button>
        </div>
      ),
    },
  ];
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };
  const getRowId = (row) => row.subscription_id;

  const handleCheckboxChange = (event, subscriptionId) => {
    const checked = event.target.checked;
    setCheckedRows((prevCheckedRows) => {
      if (checked) {
        return [...prevCheckedRows, subscriptionId];
      } else {
        return prevCheckedRows.filter((id) => id !== subscriptionId);
      }
    });
  };

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchSpecial");
  
    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchSpecial") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchSpecial", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);
  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchSpecial", query);
  };

  const filteredRows = data
    .filter((row) => row.balance_usd > 0)
    .filter((row) => row.balance > 0)
    .filter((row) => {
      const searchWords = searchQuery.split(" ");
      return searchWords.every((word) =>
        Object.values(row).some((value) =>
          String(value).toLowerCase().includes(word.toLowerCase().trim())
        )
      );
    });

    const handleFilterChange = (filterModel) => {
      if (filterModel.items.length > 0) {
        const filteredData = data.filter((row) => {
          return filterModel.items.every((filter) => {
            const { columnField, value } = filter;
            
            // Check if row[columnField] is defined
            const cellValue = row[columnField];
            
            if (cellValue !== undefined && value !== undefined) {
              return String(cellValue)
                .toLowerCase()
                .includes(value.toLowerCase());
            }
            
            // If cellValue or value is undefined, return false to exclude this row
            return false;
          });
        });
        setFilteredRowsData(filteredData);
      } else {
        setFilteredRowsData(data); // No filters, reset to original data
      }
    };

     // Define the column headers mapping
     const columnHeaders = {
      status: "Status",
      fullname_en: "Full Name",
      suffix: "Suffix",
      balance_usd: "Balance (USD)",
      balance: "Initial Balance",
      client_notes: "Client Notes",
      payment_date: "Payment Date",
      due: "Due",
      expiration_date: "Expiration Date",
      collector: "Collector",
      label: "Label",
      note_details: "Latest Note"
    };

// Function to transform the rows based on columnHeaders
const transformRowsForExport = (rows) => {
  return rows.map((row) => {
    const transformedRow = {};
    Object.keys(columnHeaders).forEach((key) => {
      transformedRow[columnHeaders[key]] = row[key];
    });
    return transformedRow;
  });
};

// Function to export to Excel
const handleExportToExcel = () => {
  // Transform the rows to match the new column headers
  const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);
  
  const worksheet = XLSX.utils.json_to_sheet(transformedRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Special");
  XLSX.writeFile(workbook, "Special.xlsx");
}
const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          fontSize: '14px',
          border: 'none',
          backgroundColor: isPressed ? 'white' : 'transparent', // Change background on press
          color: isPressed ? 'black' : '#007bff', // Change text color on press
          cursor: 'pointer',
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: '20px',
            marginRight: '8px',
            filter: isPressed ? 'invert(0)' : 'invert(1)', // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
    </GridToolbarContainer>
  );

  const handleDeselectCheckboxes = () => {
    setCheckedRows([]);
  };
  const CustomFooter = ({ selectedCount }) => {
    return (
      <GridFooterContainer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ marginLeft: "16px", color: "white" }}>
          {`Selected rows: ${selectedCount}`}
        </Typography>
        <GridPagination />
      </GridFooterContainer>
    );
  };

  return (
    <div className="InternetTable">
      <div className="actions">
        <CustomizedActions
          subscriptions={checkedRows}
          deselect={() => setCheckedRows([])}
          fetch={fetchData}
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-accounting"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 570, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
              Footer: () => <CustomFooter selectedCount={checkedRows.length} />,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            rowsPerPage={10}
            pagination
            disableSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: 'payment_date', sort: 'asc' }],
              },
            }}
          />
        </div>
      )}

      {/* Postpone Confirmation Modal */}
      <Modal show={showPostponeModal} onHide={ClosePostponeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Postpone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to postpone this subscription?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ClosePostponeModal}>
            No
          </Button>
          <Button variant="primary" onClick={handlePostponeConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={CloseCollect} className="modal-lg">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>{Fullname} Notes</Modal.Title>
          </Modal.Header>
          <Notes sub_id={Client} />
        </div>
      </Modal>
      <Modal
        show={showNotification}
        onHide={CloseNotification}
        className="modal-lg"
      >
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Notification for {Fullname}</Modal.Title>
          </Modal.Header>
          <Notification sub_id={Client} />
        </div>
      </Modal>
      <Modal show={showReceipt} onHide={CloseReceipt} className="modal-lg">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>{Fullname} Receipts</Modal.Title>
          </Modal.Header>
          <ReceiptTable client_id={Cl} />
        </div>
      </Modal>
      <Modal show={showStatement} onHide={CloseStatement} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>Statement of Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Statement client_id={Cl} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={CloseStatement}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCollectedModal} onHide={CloseCollectedModal}>
        <Modal.Header closeButton>
          <Modal.Title>To be Collected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to collect this subscription?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={CloseCollectedModal}>
            No
          </Button>
          <Button variant="primary" onClick={handleCollectedConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Special;
