import React, { useState, useEffect, useContext } from "react";
import IssuesTable from "./IssuesTable";

function Issues() {
  return (
    <div className="Plans">
      <div className="main">
        <IssuesTable />
      </div>
    </div>
  );
}

export default Issues;
