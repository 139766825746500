import React, { useState, useEffect, useContext, useRef } from "react"; 
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import './statement.css'
import logo from './logo.png'

function Statement(props) {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [entries, setEntries] = useState([]);
  const [clientName, setClientName] = useState("");
  const [villageName, setVillageName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const printRef = useRef();

  useEffect(() => {
    let isMounted = true;
    const getOrders = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/statement/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            client_id: props.client_id,
          },
        });

        if (response.status === 200 && isMounted) {
          setEntries(response.data.entries);
          setClientName(response.data.fullname_en);
          setVillageName(response.data.village_name); // Set village name
          setPhoneNumber(response.data.phone_number); // Set phone number
        }
      } catch (error) {
        console.error(error);
      }
    };
    getOrders();

    return () => {
      isMounted = false;
    };
  }, [props.client_id, user.username, authTokens.access]);

  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  const handlePrint = () => {
    if (printRef.current) {
      const printContents = `
        <html>
          <head>
            <title>Statement of Account</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
              /* Additional styling for print */
              body { font-family: Arial, sans-serif; }
              h4 { text-align: center; margin-bottom: 20px; font-size: 18pt; }
              table { width: 100%; border-collapse: collapse; }
              th, td { padding: 8px; border: 1px solid #ddd; }
              th { background-color: #f2f2f2; color: black; }
              .print-button { display: none; } /* Hide print button in print view */
              .header { display: flex; justify-content: space-between; align-items: center; }
              .date { font-weight: bold; font-size: 12pt; }
            </style>
          </head>
          <body>
            <div class="header">
              <img src="${logo}" class="logo" alt="Logo" width="25%" height="25%"/>
              <div class="date">${currentDate}</div>
            </div>
            <div style="text-align: center;">
              <h2>STATEMENT OF ACCOUNT</h2>
              <p>Omega Smart Systems</p>
              <p>Bakaata Main Road, El Chouf, Lebanon</p>
              <p>Phone: 00961-76 842 442</p>
            </div>
            <div style="margin: 20px 0;">
              <strong>BILL TO:</strong><br/>
              <strong>Client's Name:</strong> ${clientName} <br/>
              <strong>Client's Location:</strong> ${villageName} <br/>
              <strong>Client's Phone Number:</strong> ${phoneNumber} <br/>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Invoice/Receipt ID</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Total Amount</th>
                  <th>Paid Amount USD</th>
                  <th>Paid Amount LBP</th>
                </tr>
              </thead>
              <tbody>
                ${entries.map(entry => `
                  <tr>
                    <td>${entry.type === "invoice" ? entry.invoice_id : entry.receipt_id}</td>
                    <td>${entry.date}</td>
                    <td>${entry.description || "N/A"}</td>
                    <td>${entry.type}</td>
                    <td>${entry.total_amount || "N/A"}</td>
                    <td>${entry.paid_amount_usd || "N/A"}</td>
                    <td>${entry.paid_amount_lbp || "N/A"}</td>
                  </tr>`).join('')}
              </tbody>
            </table>
          </body>
        </html>
      `;
  
      const printWindow = window.open('', '', 'width=900,height=650');
      printWindow.document.write(printContents);
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("printRef is undefined.");
    }
  };
  

  return (
    <div className="testi">
      <div className="mod" ref={printRef}>
        <h4>Records for {clientName}</h4>
        <button onClick={handlePrint} className="print-button">Print Statement</button>
        <table className="table">
          <thead>
            <tr>
              <th>Invoice/Receipt ID</th>
              <th>Date</th>
              <th>Description</th>
              <th>Type</th>
              <th>Total Amount</th>
              <th>Discount</th>
              <th>Status</th>
              <th>Paid Amount USD</th>
              <th>Paid Amount LBP</th>
              <th>Discount USD</th>
              <th>Discount LBP</th>
              <th>Returned USD</th>
              <th>Returned LBP</th>
              <th>Exchange Rate</th>
              <th>Balance Record</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                <td>{entry.type === "invoice" ? entry.invoice_id : entry.receipt_id}</td>
                <td>{entry.date}</td>
                <td>{entry.description || "N/A"}</td>
                <td>{entry.type}</td>
                <td>{entry.total_amount || "N/A"}</td>
                <td>{entry.discount || "N/A"}</td>
                <td>{entry.status || "N/A"}</td>
                <td>{entry.paid_amount_usd || "N/A"}</td>
                <td>{entry.paid_amount_lbp || "N/A"}</td>
                <td>{entry.discount_usd || "N/A"}</td>
                <td>{entry.discount_lbp || "N/A"}</td>
                <td>{entry.returned_usd || "N/A"}</td>
                <td>{entry.returned_lbp || "N/A"}</td>
                <td>{entry.exchange_rate || "N/A"}</td>
                <td>{entry.balance_record || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Statement;
