import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import Modal from "react-bootstrap/Modal";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import AuthContext from "../context/AuthContext";
import ExcelSVG from '../../ExcelSVG.svg'
import Button from "react-bootstrap/Button";
import * as XLSX from "xlsx";
// For API Requests
import axios from "axios";

function IssuesTable() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

 
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/radius/issues/all/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data.reverse());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username, authTokens.access]);

  const columns = [
    { field: "issue_id", headerName: "ID", flex: 2, minWidth: 75 },
    { field: "username", headerName: "Username", flex: 2, minWidth: 100 },
    { 
      field: "fullname_en", 
      headerName: "Client Name", 
      flex: 2,
      width: 'auto',
      minWidth: 150,
      maxWidth: 150,
      renderCell: (params) => (
        <div style={{ 
          whiteSpace: 'normal', 
          wordWrap: 'break-word',
          lineHeight: '1.2',
          padding: '8px 0'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: "description", 
      headerName: "Description",
      flex: 2,
      width: 'auto',
      minWidth: 500,
      maxWidth: 500,
      renderCell: (params) => (
        <div style={{ 
          whiteSpace: 'normal', 
          wordWrap: 'break-word',
          lineHeight: '1.2',
          padding: '8px 0'
        }}>
          {params.value}
        </div>
      )
    },

    {
      field: "creation_date",
      headerName: "Creation Date",
      minWidth: 230,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "employee_name",
      headerName: "Employee",
      minWidth: 150,
    },

  ];

  const getRowId = (row) => row.issue_id;

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchIssues");
  
    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchIssues") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchIssues", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);
  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchIssues", query);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  
  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;
          
          // Check if row[columnField] is defined
          const cellValue = row[columnField];
          
          if (cellValue !== undefined && value !== undefined) {
            return String(cellValue)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
          
          // If cellValue or value is undefined, return false to exclude this row
          return false;
        });
      });
      setFilteredRowsData(filteredData);
    } else {
      setFilteredRowsData(data); // No filters, reset to original data
    }
  };

  const columnHeaders = {
    issue_id: "ID",
    username: "Username",
    fullname_en: "Full Name",
    description: "Description",
    creation_date:"Creation Date",
    employee_name:"Employee",

  };
  // Function to transform the rows based on columnHeaders
const transformRowsForExport = (rows) => {
  return rows.map((row) => {
    const transformedRow = {};
    Object.keys(columnHeaders).forEach((key) => {
      transformedRow[columnHeaders[key]] = row[key];
    });
    return transformedRow;
  });
};

const handleExportToExcel = () => {
  // Transform the rows to match the new column headers
  const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);

  const worksheet = XLSX.utils.json_to_sheet(transformedRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Fiber Cores");
  XLSX.writeFile(workbook, "Fiber Cores.xlsx");
};

const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          fontSize: '14px',
          border: 'none',
          backgroundColor: isPressed ? 'white' : 'transparent', // Change background on press
          color: isPressed ? 'black' : '#007bff', // Change text color on press
          cursor: 'pointer',
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: '20px',
            marginRight: '8px',
            filter: isPressed ? 'invert(0)' : 'invert(1)', // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
    </GridToolbarContainer>
  );

  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
        }}
      >
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
            getRowHeight={() => 80} // Set a fixed row height
            headerHeight={56} // Optional: Adjust header height
            sx={{
              '& .MuiDataGrid-row': {
                minHeight: '80px !important', // Ensure minimum row height
                alignItems: 'center',
              },
              '& .MuiDataGrid-cell': {
                padding: '8px',
                alignItems: 'center',
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default IssuesTable;
