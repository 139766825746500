import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import { useHistory } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridFooter,
  GridPagination
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import ExcelSVG from '../../ExcelSVG.svg'
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Checkbox from "@mui/material/Checkbox";
import CustomizedActions from "./BulkActions";
import AuthContext from "../context/AuthContext";
import Formsa from "./editsub";
import Ping from "./ping";
import Cards from "./labels";

import ConsumptionHistory from "./consumptionHistory";
import Issues from "./issues";
import IssuesHistory from "./issuesHistory";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ProgressBar from "./progress.js";
import "./Internet.css";
import ActionTable from './ActionLogTable';

function SubInternet(props) {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedRows, setCheckedRows] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // To capture search input
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null); // For delayed search filter

  const [showo, setShowo] = useState(false);
  const [password, setpassword] = useState(0);
  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const [showPing, setShowPing] = useState(false);
  const handleshowPing = () => setShowPing(true);
  const handleClosePing = () => setShowPing(false);

  const [showIssues, setShowIssues] = useState(false);
  const handleshowIssues = () => setShowIssues(true);
  const handleCloseIssues = () => setShowIssues(false);

  const [showHistory, setShowHistory] = useState(false);
  const handleshowHistory = () => setShowHistory(true);
  const handleCloseHistory = () => setShowHistory(false);

  const [showLog, setShowLog] = useState(false);
  const handleShowLog = () => setShowLog(true);
  const handleCloseLog = () => setShowLog(false)

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const handleUpdateAct = (id) => setSelectedSubscriptionId(id);
  const history = useHistory();
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    username: "",
    password: 0,
    subId: 0,
    intSubId: 0,
    intPlanId: 0,
    site: "",
    planName: "",
    fullName: "",
    expDate: "",
    paymentDate:"",
    consumption: "",
    connectivity: "",
    due: "",
  });

  const updateSubscriptionDetails = (details) => {
    setSubscriptionDetails(details);
  };
  const issues = (details) => {
    setSubscriptionDetails(details);
    history.push("/issues", { myProp: details.subId });
  };

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/radius/subscriptions/free/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();

    // Set up a timer to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 6000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, [user.username]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const paginatedRows = () => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return filteredRows.slice(start, end);
  };

  const areAllRowsSelected = () => {
    const currentViewRows = paginatedRows();
    const currentViewIds = currentViewRows.map(row => row.subscription_id);
    return currentViewIds.every(id => checkedRows.includes(id));
  };

  const handleSelectAllCheckboxes = () => {
    const currentViewRows = paginatedRows(); // Get rows currently visible
    const currentViewIds = currentViewRows.map(row => row.subscription_id);

    if (areAllRowsSelected()) {
      // Deselect if all currently visible rows are selected
      setCheckedRows(checkedRows.filter(id => !currentViewIds.includes(id)));
    } else {
      // Select all visible rows if not all are selected
      setCheckedRows([...checkedRows, ...currentViewIds.filter(id => !checkedRows.includes(id))]);
    }
  };
  const columns = [
    
    {
      field: "checkbox",
      headerName: "",
      width: 100,
      renderHeader: () => (
        <Checkbox
          indeterminate={checkedRows.length > 0 && checkedRows.length < data.length}
          checked={checkedRows.length === data.length}
          onChange={(event) => handleSelectAllCheckboxes(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          className="subcheck"
          checked={checkedRows.includes(params.row.subscription_id)}
          onChange={(event) =>
            handleCheckboxChange(event, params.row.subscription_id)
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 70,
      cellClassName: (params) => getStatusCellStyle(params), // Apply cell style based on status
    },
    { field: "username", headerName: "Username", minWidth: 100 },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    { field: "suffix", headerName: "Suffix", flex: 1, minWidth: 150 },

    {
      field: "plan_name",
      headerName: "Plan Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        let max = params.row.daily_limit;
        let download = params.row.pppoe_download;
        let upload = params.row.pppoe_upload;

        if (params.row.plan_name.includes("STARTER")) {
          max = params.row.monthly_limit;
          download = params.row.monthly_download;
          upload = params.row.monthly_upload;
        }

        return (
          <ProgressBar
            planName={params.row.plan_name}
            max={max}
            download={download}
            upload={upload}
          />
        );
      },
    },
    {
      field: "labels",
      headerName: "Labels",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Cards sub_id={params.row.subscription_id} />;
      },
    },
    { field: "ip_address", headerName: "IP Address", flex: 1, minWidth: 120 },
    { field: "site", headerName: "Site/Core", flex: 1, minWidth: 85 },
    {
      field: "subscription_secret",
      headerName: "Token",
      flex: 1,
      minWidth: 95,
    },
    { field: "dealer_en", headerName: "Reseller", flex: 1, minWidth: 80 },
    {
      field: "connectivity",
      headerName: "connectivity",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "consumption_option",
      headerName: "Option",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "points",
      headerName: "Omegas",
      flex: 1,
      minWidth: 70,
    },
    {
      field: "mac_address",
      headerName: "Mac Address",
      flex: 1,
      minWidth: 70,
    },
    { field: "due", headerName: "Due", flex: 1, minWidth: 50 },
    {
      field: "payment_date",
      headerName: "Payment",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "expiration_date",
      headerName: "Expiry",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "creation_date",
      headerName: "Creation",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <div>
          <div
            onClick={() => handleUpdateAct(params.row.subscription_id)}
          ></div>
          <button
            onClick={() => {
              const details = {
                username: params.row.username,
                password: params.row.password,
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
                intPlanId: params.row.internet_plan_id,
                site: params.row.site,
                planName: params.row.plan_name,
                fullName: params.row.fullname_en,
                expDate: params.row.expiration_date,
                expDate: params.row.expiration_date,
                paymentDate: params.row.payment_date,
                consumption: params.row.consumption_option,
                connectivity: params.row.connectivity,
                due: params.row.due,
              };
              updateSubscriptionDetails(details);
              handleshowo();
            }}
            className="edit-client"
          >
            Edit
          </button>
          <button
            onClick={() => {
              const details = {
                ip: params.row.ip_address,
                nas: params.row.nas_ip_address,
              };
              updateSubscriptionDetails(details);
              handleshowPing();
            }}
            className="edit-client"
          >
            Ping
          </button>
          <button
            onClick={() => {
              const details = {
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
              };
              updateSubscriptionDetails(details);
              issues(details);
            }}
            className="edit-client"
          >
            History
          </button>
          <button
            onClick={() => {
              const details = {
                username: params.row.username,
                
              };
              updateSubscriptionDetails(details);
              handleShowLog();
            }}
            className="edit-client"
          >
            View Logs
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.subscription_id;

  const handleCheckboxChange = (event, subscriptionId) => {
    const checked = event.target.checked;
    setCheckedRows((prevCheckedRows) => {
      if (checked) {
        return [...prevCheckedRows, subscriptionId];
      } else {
        return prevCheckedRows.filter((id) => id !== subscriptionId);
      }
    });
  };

  // Create a delayed search filter function
  const delaySearchFilter = (input) => {
    setSearchQuery(input);
  };

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchFreeSubscription");
  
    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchFreeSubscription") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchFreeSubscription", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchInput(query);
    sessionStorage.setItem("searchFreeSubscription", query);

    // Clear the previous timeout if it exists
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }

    // Set a new timeout to trigger filtering after a delay
    const newTimeout = setTimeout(() => {
      delaySearchFilter(query);
    }, 100); // Adjust the delay as needed
    setSearchFilterTimeout(newTimeout);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;
          
          // Check if row[columnField] is defined
          const cellValue = row[columnField];
          
          if (cellValue !== undefined && value !== undefined) {
            return String(cellValue)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
          
          // If cellValue or value is undefined, return false to exclude this row
          return false;
        });
      });
      setFilteredRowsData(filteredData);
    } else {
      setFilteredRowsData(data); // No filters, reset to original data
    }
  };

  const handleDeselectCheckboxes = () => {
    setCheckedRows([]);
  };

  const columnHeaders = {
    status: "Status",
    username: "Username",
    fullname_en: "Full Name",
    suffix: "Suffix",
    plan_name: "Plan Name",
    labels: "Labels",
    ip_address: "IP Address",
    site: "Site/Core",
    subscription_secret: "Token",
    dealer_en: "Reseller",
    connectivity: "Connectivity",
    consumption_option: "Option",
    points: "Omegas",
    due: "Due",
    payment_date: "Payment",
    mac_address:"Mac Address",
    expiration_date: "Expiry",
    creation_date: "Creation",
    actions: "Actions",
  };
  

 // Function to transform the rows based on columnHeaders
const transformRowsForExport = (rows) => {
  return rows.map((row) => {
    const transformedRow = {};
    Object.keys(columnHeaders).forEach((key) => {
      transformedRow[columnHeaders[key]] = row[key];
    });
    return transformedRow;
  });
  };
  
  // Function to export to Excel
  const handleExportToExcel = () => {
  // Transform the rows to match the new column headers
  const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);
  
  const worksheet = XLSX.utils.json_to_sheet(transformedRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Subscription");
  XLSX.writeFile(workbook, "Subscription.xlsx");
  }
  const [isPressed, setIsPressed] = useState(false);
  
  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };
  
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          fontSize: '14px',
          border: 'none',
          backgroundColor: isPressed ? 'white' : 'transparent', // Change background on press
          color: isPressed ? 'black' : '#007bff', // Change text color on press
          cursor: 'pointer',
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: '20px',
            marginRight: '8px',
            filter: isPressed ? 'invert(0)' : 'invert(1)', // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
    </GridToolbarContainer>
  );
  const CustomFooter = ({ selectedCount }) => {
    return (
      <GridFooterContainer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ marginLeft: "16px", color: "white" }}>
          {`Selected rows: ${selectedCount}`}
        </Typography>
        <GridPagination />
      </GridFooterContainer>
    );
  };
  return (
    <div className="InternetTable ">
      <div className="actions">
        <CustomizedActions
          subscriptions={checkedRows}
          deselect={handleDeselectCheckboxes}
          fetch={fetchData}
        />
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Footer: () => <CustomFooter selectedCount={checkedRows.length} />,
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            rowsPerPage={10} // Set the default number of rows per page to 10
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <Formsa
            su={subscriptionDetails.subId}
            intsu={subscriptionDetails.intSubId}
            intpa={subscriptionDetails.intPlanId}
            usr={subscriptionDetails.username}
            full={subscriptionDetails.fullName}
            sit={subscriptionDetails.site}
            con={subscriptionDetails.connectivity}
            opti={subscriptionDetails.consumption}
            paymentDate={subscriptionDetails.paymentDate}
            plana={subscriptionDetails.planName}
            exp={subscriptionDetails.expDate}
            pass={subscriptionDetails.password}
            due={subscriptionDetails.due}
            closeModal={handleedit}
            fetch={fetchData}
          />
        </div>
      </Modal>
      <Modal show={showPing} onHide={handleClosePing}>
        <Modal.Header closeButton>
          <Modal.Title>Ping</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <Ping ip={subscriptionDetails.ip} nas={subscriptionDetails.nas} />
        </div>
      </Modal>
      
      <Modal show={showLog} onHide={handleCloseLog} className="modal-xl">
      <Modal.Header closeButton>
        <Modal.Title>Action Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActionTable subscription_username={subscriptionDetails.username} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseLog}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}

export default SubInternet;
