import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import EditService from "./subscriptionServiceEdit";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import ExcelSVG from '../../ExcelSVG.svg'
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";

function ServiceTable() {
  const [data, setData] = useState([]);
  const [showo, setShowo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(AuthContext);
  const [DealerDetails, setDealerDetails] = useState({
    subscription_service_id: 0,
    username:0,
    fullname_en:0,
    name:0

  });

  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const updateDealerDetails = (details) => {
    console.log("Updating DealerDetails:", details); // Debugging log
    setDealerDetails(details);
  };

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(
          `${API_URL}/radius/subscriptions/services/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
            params: {
              username: user.username,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
          console.log("Fetched data:", response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.username]);

  const columns = [
    { field: "status", headerName: "Status", flex: 2, minWidth: 50 },
    { field: "username", headerName: "Username", flex: 2, minWidth: 50 },
    { field: "fullname_en", headerName: "Client", flex: 2, minWidth: 50 },
    { field: "name", headerName: "Service", flex: 2, minWidth: 50 },
    { field: "description", headerName: "Description", flex: 2, minWidth: 50 },
    {
      field: "selling_price",
      headerName: "Price",
      flex: 1,
      minWidth: 60,
      valueFormatter: (params) => `$ ${params.value}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 2,
      renderCell: (params) => (
        <div>
          <button
            className="edit-client"
            onClick={() => {
              console.log("Row data:", params.row); // Debugging log
              const details = {
                subscription_service_id: params.row.subscription_service_id,
                username:params.row.username,
                fullname_en:params.row.fullname_en,
                name:params.row.name,

              };
              updateDealerDetails(details);
              handleshowo();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.subscription_service_id;

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;
          
          // Check if row[columnField] is defined
          const cellValue = row[columnField];
          
          if (cellValue !== undefined && value !== undefined) {
            return String(cellValue)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
          
          // If cellValue or value is undefined, return false to exclude this row
          return false;
        });
      });
      setFilteredRowsData(filteredData);
    } else {
      setFilteredRowsData(data); // No filters, reset to original data
    }
  };

  // Define the column headers mapping
const columnHeaders = {
status: "Status",
username:"Username",
fullname_en: "Client",
name: "Service",
description:"Description",
selling_price:"Price",
};

// Function to transform the rows based on columnHeaders
const transformRowsForExport = (rows) => {
return rows.map((row) => {
  const transformedRow = {};
  Object.keys(columnHeaders).forEach((key) => {
    transformedRow[columnHeaders[key]] = row[key];
  });
  return transformedRow;
});
};

const handleExportToExcel = () => {
// Transform the rows to match the new column headers
const transformedRows = filteredRowsData.length > 0 
    ? transformRowsForExport(filteredRowsData) 
    : transformRowsForExport(filteredRows);

const worksheet = XLSX.utils.json_to_sheet(transformedRows);
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, "Collector");
XLSX.writeFile(workbook, "Collector.xlsx");
};
const [isPressed, setIsPressed] = useState(false);

const handleExportClick = () => {
  setIsPressed(true);
  handleExportToExcel();
  // Optional: Reset the button state after a short delay
  setTimeout(() => {
    setIsPressed(false);
  }, 200); // Adjust the timeout as needed
};

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarExport />
    <Button
      onClick={handleExportClick}
      variant="outline-success"
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        fontSize: '14px',
        border: 'none',
        backgroundColor: isPressed ? 'white' : 'transparent', // Change background on press
        color: isPressed ? 'black' : '#007bff', // Change text color on press
        cursor: 'pointer',
      }}
    >
      <img
        src={ExcelSVG}
        alt="Excel Icon"
        style={{
          width: '20px',
          marginRight: '8px',
          filter: isPressed ? 'invert(0)' : 'invert(1)', // Make the SVG white or retain its original color
        }}
      />
      Excel
    </Button>
  </GridToolbarContainer>
);

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Service</Modal.Title>
        </Modal.Header>
        <EditService
        subscription_service_id={DealerDetails.subscription_service_id}
        fullname_en={DealerDetails.fullname_en}
        username={DealerDetails.username}
        name={DealerDetails.name}
        />
      </Modal>
    </div>
  );
}

export default ServiceTable;
