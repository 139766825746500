import React, { useState, useEffect, useContext } from "react";
import "./forma.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";

function Formo(props) {
  let [notes, setvillage] = useState([]);
  let [employee, setemployee] = useState([]);

  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  let r = props.remote;
  let o = props.office;
  let [remote, setRemote] = useState(r); // New state for remote, default value 0
  let [office, setOffice] = useState(o);
  let [group, setgroup] = useState(""); // New state for remote, default value 0

  function refreshPage() {
    window.location.reload(false);
  }
  useEffect(() => {
    let isMounted = true;

    const getvillage = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/villages/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: "admin",
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setvillage(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getpermissions = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setgroup(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const getemployee = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/administration/employees/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: "admin",
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setemployee(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getvillage();
    getemployee();
    getpermissions();

    return () => {
      isMounted = false;
    };
  }, []);

  // Rest of the component code
  const options = notes.map((item) => {
    return {
      label: item.village_name,
      value: item.village_id,
    };
  });
  const employees = employee.map((item) => {
    return {
      label: item.first_name,
      value: item.employee_id,
    };
  });
  console.log(props.remote);
  let vilo = props.vill;
  let v = props.sub;

  let f = props.add;
  let l = props.inf;
  let y = props.fir;
  let z = props.mid;
  let d = props.las;
  let i = props.pho;
  let k = props.addr;
  let b = props.balance;
  let col = props.collector;
  const [firstname, setfirst] = useState("");
  const [middlename, setmiddles] = useState("");
  const [lastname, setlast] = useState("");
  const [address, setaddress] = useState("");
  const [phone, setphone] = useState("");
  const [balance, setbalance] = useState("");
  const [collector, setcollector] = useState("");
  const [category, setcategory] = useState("");

  const balance_id = props.balance_id;
  console.log(collector);
  const [village, setvill] = useState("");
  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/administration/clients/`,
      {
        client_id: v,
        info_id: l,
        address_id: f,
        Action: "Put",
        first_name: firstname,
        email_address: "-",
        address_string: address,
        phone_number: phone,
        middle_name: middlename,
        last_name: lastname,
        village_id: village,
        collector_id: collector,
        balance_id: balance_id,
        category: category,
        balance: balance,
        remote: remote, // Include remote in the POST request payload
        office: office, // Include office in the POST request payload

        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dua = [
    { value: "VIP", label: "VIP" },
    { value: "Standard", label: "Standard" },
    { value:"Special", label:"Special"},
    { value:"Blacklist", label:"Blacklist"},
  ];
  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="name">
          <div className="form-group">
            <label>
              First Name:
              <input
                className="form-control"
                type="text"
                value={firstname}
                placeholder={y}
                onChange={(e) => setfirst(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Middle Name:
              <input
                className="form-control"
                type="text"
                value={middlename}
                placeholder={z}
                onChange={(e) => setmiddles(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Last Name:
              <input
                className="form-control"
                type="text"
                value={lastname}
                placeholder={d}
                onChange={(e) => setlast(e.target.value)}
              />
            </label>
          </div>
        </div>

        <label>
          Phone:
          <input
            className="form-control"
            type="number"
            value={phone}
            placeholder={i}
            onChange={(e) => setphone(e.target.value)}
          />
        </label>
        <label>
          Address:
          <input
            className="form-control"
            type="text"
            value={address}
            placeholder={k}
            onChange={(e) => setaddress(e.target.value)}
          />
        </label>
        <label>
          Village:
          <Select
            className="search-line"
            placeholder={vilo}
            options={options}
            onChange={(opt) => setvill(opt.value)}
          />
        </label>
        <label>
          Balance:
          <input
            className="form-control"
            type="text"
            value={balance}
            placeholder={b}
            onChange={(e) => setbalance(e.target.value)}
            disabled={group !== "yes"} // Disable unless group is 'yes'
          />
        </label>
        <label>
          Collector:
          <Select
            className="search-line"
            placeholder={props.employee_en}
            options={employees}
            onChange={(opt) => setcollector(opt.value)}
          />
        </label>
        <label>
          Category:
          <Select
            className="search-line"
            options={dua}
            placeholder={props.category}
            onChange={(opt) => setcategory(opt.value)}
          />
        </label>
        <div
          className="form-group"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label style={{ marginRight: "10px" }}>Temp Collection:</label>
          <input
            type="checkbox"
            checked={remote === 1}
            onChange={(e) => setRemote(e.target.checked ? 1 : 0)}
            style={{ width: "20px", height: "20px" }}
          />
        </div>
        <div
          className="form-group"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label style={{ marginRight: "10px" }}>Temp Collection Office:</label>
          <input
            type="checkbox"
            checked={office === 1}
            onChange={(e) => setOffice(e.target.checked ? 1 : 0)}
            style={{ width: "20px", height: "20px" }}
          />
        </div>

        <div className="fo">
          <button type="submit" className="btn btn-primary btn-md">
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}

export default Formo;
